.earth-worthy-logo{
    margin-left: 30px !important;
}

.earthy-worthy-carousel{
    height: 650px !important;
}

.earthy-feature-collections{
    background-color: #272829;
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.subhcards-feature-collections{
    opacity: 0.5;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.luckme-feature-collections{
    // background-color: #2e3093;
    opacity: 0.4;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    
}

#luckmebrands{
    .tab-image {
        min-height: 300px;
        max-height: 300px;
        text-align: center;
        position: relative;
        margin-bottom: 30px;
        cursor: pointer;
        // overflow: hidden;
        position: relative;
        .background-image {
          background-size: 100% 300px;
        //   height: 300px !important;
          background-repeat: no-repeat;
          -webkit-transition: all .5s;
          -moz-transition: all .5s;
          -o-transition: all .5s;
          transition: all .5s;
              min-height: 300px;
              &:hover {
                transform: scale(1.2);
              }
        }
        .bg-content{
          position: absolute;
          top:50%;
          left:50%;
          transform: translate(-50%, -50%);
           color:#fff;
          font-size: 25px;
         
            p{
                font-size: 15px;
            }
         
        }
        
}
}

.luckme-tabimage{
   border: 1px solid rgb(194, 194, 194) !important;
   border-radius: 10px;
}
.earthy-tabimage{
    border: 1px solid rgb(194, 194, 194) !important;
    border-radius: 10px;
 }
.shubcards-tabimage{
    border: 1px solid #CC5500 !important;
    border-radius: 20px;
 }
 .shubcards-tabimage1{
    border: 1px solid #2554C7 !important;
    border-radius: 20px;
 }




.earthy-card{
    @if $product-cd == "earthyworthy" or $product-cd == "tams" or $product-cd == "bombayhardware"  {
        margin: 15px !important;
        min-height: 340px;
        max-height: 380px;
        box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    }
    @else{
        display : none;
    }
}

.earthy-image{
    min-height: 200px;
    max-height: 240px;
    padding: 10px;
}

.earthy-description{
    // box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
    padding: 10px;
    min-height: 100px;
    max-height: 140px;
    background-color: $white;
}

.earthy-product-title{
    color: $primary;
    font-weight: 500;
    font-size: 16px;
}

.earthy-worthy-price{
    padding-top: 5px;
    font-weight: 500;
    font-size: 15px;
}

.earthy-cart-btn{
    display: none;
}

// .earthy-card:hover{
//     min-height: 320px;
//     max-height: 320px;
//     box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
//     .earthy-image{
//         min-height: 180px;
//         max-height: 180px;
//         padding: 10px;
//     }
//     .earthy-description{
//         box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
//         min-height: 140px;
//         max-height: 140px;
//     }
//     .earthy-product-title{
//         margin-top: 5px;
//         font-size: 14px;
//     }
//     .earthy-cart-btn{
//         padding-top: 10px;
//         display: block;
//     }

//     .earthy-btn{
//         border-radius: 6px !important;
//         padding: 5px 20px !important;
//         background-color: #BDC62B !important;
//         border: none !important;
//         border-radius: 20px !important;
//         font-size: 13px !important;
//         font-weight: 500 !important;
//     }
//     .shubhcards-btn{
//         border-radius: 6px !important;
//         padding: 5px 20px !important;
//         background-color: #150985 !important;
//         border: none !important;
//         border-radius: 20px !important;
//         font-size: 13px !important;
//         font-weight: 500 !important;
//         border: 1px solid #CC5500 !important;
        
//     }
//     .beaubelle-btn{
//         border-radius: 6px !important;
//         padding: 5px 20px !important;
//         background-color: #e7762d !important;
//         border: none !important;
//         border-radius: 20px !important;
//         font-size: 13px !important;
//         font-weight: 500 !important;
//         border: 1px solid #CC5500 !important;
        
//     }
// }

.earthyworthy-footer{
    color: #212529 !important;
}

// LUCK ME

.luckme-card{
    @if $product-cd == "earthyworthy" or $product-cd == "tams" {
        margin: 5px !important;
        min-height: 350px;
        max-height: 350px;
        box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    }
    @else{
        display : none;
    }
}

.luckme-image{
    min-height: 220px;
    max-height: 220px;
    padding: 10px;
}

.luckme-description{
    // box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
    padding: 10px;
    min-height: 130px;
    max-height: 130px;
    background-color: $white;
}

.luckme-product-title{
    color: $primary;
    font-weight: 500;
    font-size: 16px;
}

.luckme-worthy-price{
    padding-top: 5px;
    font-weight: 500;
    font-size: 15px;
}

.luckme-cart-btn{
    display: none;
}

.luckme-card:hover{
    min-height: 350px;
    max-height: 350px;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
    .luckme-image{
        min-height: 190px;
        max-height: 190px;
        padding: 10px;
        object-fit: contain !important;
    }
    .luckme-description{
        box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
        min-height: 160px;
        max-height: 160px;
    }
    .luckme-product-title{
        margin-top: 5px;
        font-size: 14px;
    }
    .luckme-cart-btn{
        padding-top: 10px;
        display: block;
    }
    .shubhcards-cart-btn{
        padding-top: 10px;
        display: block;
    }

    .luckme-btn{
        border-radius: 6px !important;
        padding: 5px 20px !important;
        background-color: #BDC62B !important;
        border: none !important;
        border-radius: 20px !important;
        font-size: 13px !important;
        font-weight: 500 !important;
    }
}

.luckme-footer{
    color: #212529 !important;
}