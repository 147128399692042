.product-listing-card{
    background-color: #f3f2f8;
    border: none;
    border-radius: 20px;
    width: 100%;
    height: auto;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.product-list-cards{
    background-color: #f3f2f8;
    border:none;
    border-radius: 10px;
    width: 100%;
    min-height: 400px !important;
    max-height:600px !important;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2), 0 4px 4px 0 rgba(0, 0, 0, 0.19);

}

.tams-product-list-cards{
    background-color: white;
    border:none;
    border-radius: 10px;
    width: 90%;
    min-height: 356px !important;
    max-height:600px !important;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2), 0 4px 4px 0 rgba(0, 0, 0, 0.19);

}



.product-list-cards1{
    border:none;
    border-radius: 10px;
    // width: 100%!important;
    min-width: 300px !important;
    max-width:300px !important;
    min-height: 400px !important;
    max-height:600px !important;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2), 0 4px 4px 0 rgba(0, 0, 0, 0.19);
    padding-top: 30px;

}



// .bestgifts-list-cards{
//     border:none;
//     border-radius: 10px;
//     min-width: 300px !important;
//     max-width:300px !important;
//     min-height: 400px !important;
//     max-height:600px !important;
//     box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2), 0 4px 4px 0 rgba(0, 0, 0, 0.19);
//     padding-top: 30px;

// }

@media (max-width: 1920px) {
    .bestgifts-list-cards{
        border:none;
    border-radius: 10px;
     min-width: 300px !important;
     max-width:300px !important;
    min-height: 400px !important;
    max-height:600px !important;
     box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2), 0 4px 4px 0 rgba(0, 0, 0, 0.19);
    padding-top: 30px;
    
    }
    .bestgifts-list-cards1{
      justify-content: space-between !important;
    }

}


@media (min-width: 360px) and (max-width: 600px) {
    .bestgifts-list-cards{
        border:none;
        border-radius: 0px;
        min-width: 156px !important;
        max-width:156px !important;
        min-height: 260px !important;
        max-height:260px !important;
        box-shadow:none;
        padding-top: 30px;
    }

    .bestgifts-list-cards1{
        justify-content: center !important;
    }
}



.seller{
    width:150px;
    height:30px;
    background-color:#2554C7;
    padding:5px;
    border-radius:4px;
    color:#fff;
    font-size:14px;
    
}


.mayan-product-list-cards{
    background-color: lightgray;
    border:none;
    border-radius: 10px;
    width: 100%;
    min-height: 400px !important;
    max-height:600px !important;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2), 0 4px 4px 0 rgba(0, 0, 0, 0.19);

}
.btn-default{
    background-color: red;
}
.text-for-listing {
    display: block;
    position: absolute;
    width: 20px;
    height: 20px;
    right: -3px;
    top: -5px;
    font-size: 1rem;
    text-align: center;
    color: #777;
    border-radius: 50%;
    // background: #777;
    cursor: pointer;
    transition:all 0.5s;
  }

  .info-class{
      visibility: visible;
  }
    .product-info-list{
        visibility: hidden;
        font-weight: bold;
    }

//   .info-class{
//       visibility: visible;
//   }
//     .product-info-list{
//         visibility: hidden;
//         font-weight: bold;
//     }

//   .text-for-listing:hover{
//       color: black;
//     width: 100%;
//     height: 370px;
//     right: 0px;
//     top: 0px;
//     border-radius: 5px;
//     background: rgba($primary,.2);
//     transform:all 0.5s;
//     .info-class{
//         visibility: hidden;
//     }
//       .product-info-list{
//           visibility: visible;
//       }
//   }

// .product-list-image{
//     max-height: 250px;
//     width: 100%;
//     border-radius: 20px;
//     border-bottom-right-radius: 0px !important;
//     border-bottom-left-radius: 0px !important;
// }

// .product-list-description{
//     padding: 5px 15px !important;
//     min-height: 120px !important;
// }

// .product-list-title{
//     font-size: 16px !important;
//     font-weight: bold;
// }

// .product-list-price{
//     color: #ec6014 !important;
//     font-weight: bolder !important;
// }

// .product-button-card{
//     margin-top: 15px !important;
//     border-radius: 20px !important;
//     background-color: #ec6014 !important;
//     color: white;
//     padding: 5px 25px !important;
//     border-color: #ec6014 !important;
// }

.product-listing-container{
    margin: 10px 20px !important;
}

.product-list-card{
    background-color: #ffffff !important;
    border-radius: 10px !important;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19) !important;
    border: none !important;
    height: auto !important;
}

.product-list-image{
    min-height: 230px !important;
    max-height: 230px !important;
    width: 100% !important;
    border-radius: 10px !important;
    object-fit: contain;
}

.product-list-description{
    padding: 10px 15px !important;
    margin-bottom: 10px !important;
}

.product-list-tile{
    font-size: 16px !important;
    color: #000 !important;
    font-weight: 600 !important;
}

.product-list-tile-mayan{
    font-size: 16px !important;
    color: #000000 !important;
    font-weight: 600 !important;
}

.product-list-price{
    font-size: 16px !important;
    color: #d54604 !important;
    font-weight: 600 !important;
}

.mayan-product-list-price{
    font-size: 16px !important;
    color: brown !important;
    font-weight: 600 !important;
}

.product-weight-card{
    padding: 2px 10px !important;
    border: 1px solid $primary !important;
    margin: 3px !important;
    border-radius: 50%;
    // box-shadow: 0 4px 8px 0 rgba(88, 88, 88, 0.2), 0 6px 20px 0 rgba(88, 88, 88, 0.2) !important;
    color: $primary !important;
    cursor: pointer !important;
}

.product-weight-card:hover{
    background-color: $primary !important;
    color: $white !important;
    box-shadow: 0 4px 8px 0 rgba(88, 88, 88, 0.2), 0 6px 20px 0 rgba(88, 88, 88, 0.2) !important;
}

.product-list-cart{
    font-size: 13px !important;
    // color: #255763 !important;
    color: $primary !important;
    font-weight: bold !important;
    cursor: pointer;
    // background-color: #255763 !important;
    // border-radius: 10px !important;
    // padding: 10px 25px !important;
}

.natural-main-text{
    color:$primary !important;
    font-family: 'Segoe UI' !important;
    font-size: 19px !important;
    font-style: italic !important;
    padding-top: 15px !important;
    // animation: glow 2s ease-in-out infinite alternate;
    @keyframes glow {
        from {
          text-shadow: 0 0 20px #32cd32;
        }
        to {
          text-shadow: 0 0 30px #32cd32, 0 0 10px #32cd32;
        }
      }
}

.tams-user-icon{
    i.fa{
        padding: 0px !important;
    }
    // padding-top: 15px !important;
    border: 1px solid #777 !important;
    color: #777 !important;
    padding: 5px 9px !important;
    border-radius: 50%;
    margin-right: 5px !important;
}