.cart-link:hover
{
    cursor: pointer;
}
.sticky-header {
  @include header-mx($white)
}

// NEW DESIGNS
.logo-header-image{
  object-fit: contain;
  width: 125px;
  height: 80px;
  margin-left: 40px;
}

.header-avatar{
  color: #494949 !important;
  cursor: pointer;
}

.signin-signup{
  color: #494949 !important;
  padding-top: -5px;
  padding-left: 2px;
  cursor: pointer;
}

.counting{
  color: $primary !important;
  position: absolute;
  @if $product-cd == "nesma"{
    background: #64cce0;
  }
  @else{
    background: #DC6464;
  }
  width: 27px;
  height: 20px;
  border-radius: 9px;
  top: -1%;
  margin-top:7px;
}

.counting1{
  color: $primary !important;
  position: absolute;
  @if $product-cd == "nesma"{
    background: #64cce0;
  }
  @else{
    background: #DC6464;
  }
  width: 18px;
  height: 18px;
  border-radius: 9px;
  top: -13%;
  margin-left: -5%;
}

#murugesan{
  .nav-link{
      color: #be7c12 !important;
      font-weight: bold !important;
  }

  .nav-link:active{
      text-decoration: #D4242C;
  }
}

.topcategory-img{
  display: block;
  position: relative;
  border-radius: 50%;
  margin: 0 auto 5px;
  overflow: hidden;
  border: 1px ridge #D4242C;
  width: 130px;
  height: 130px;
  padding:10px;
  cursor: pointer;
  transition: width 0.5s, height 0.8s;
  transition-timing-function: ease;
}

.mayantopcategory-img{
  display: block;
  position: relative;
  border-radius: 50%;
  margin: 0 auto 5px;
  overflow: hidden;
  border: 1px ridge $primary;
  width: 150px;
  height: 150px;
  padding:10px;
  cursor: pointer;
  transition: width 0.5s, height 0.8s;
  transition-timing-function: ease;
}

.mayanLargetopcategory-img{
  display: block;
  position: relative;
  border-radius: 50%;
  margin: 0 auto 5px;
  overflow: hidden;
  border: 1px ridge $primary;
  width: 200px;
  height: 200px;
  padding:10px;
  cursor: pointer;
  transition: width 0.5s, height 0.8s;
  transition-timing-function: ease;
}

.mayantopcategory1-img{
  display: block;
  position: relative;
  // border-radius: 50%;
  margin: 0 auto 5px;
  overflow: hidden;
  // border: 1px ridge $primary;
  width: 15px;
  height: 90px;
  padding:10px;
  cursor: pointer;
  transition: width 0.5s, height 0.8s;
  transition-timing-function: ease;
}

.topcategory-text{
  margin: 0;
  text-transform: uppercase;
  color: #222;
  background-color: rgba(255,255,255,1);
  font-weight: 600;
  font-size: 12px;
  text-align: center;
  line-height: 18px;
  letter-spacing: 0.8px;
  position: relative;
  z-index: 1;
  white-space: normal;
}

.topcategory-img:hover{
  border: none;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  cursor: pointer;
  width: 135px;
  height: 135px;
}

.mayantopcategory-img:hover{
  border: none;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  cursor: pointer;
  width: 205px;
  height: 205px;
}

.top-image-cover{
  padding-right: 35px;
}

.categoryList:hover {
  color: #fbcb0c;
}

.image-container-top{
  margin: 50px 0px !important;
}

.welcom-text{
  color: #be7c12;
  font-size: 2rem;
  font-weight: 800;
  padding-bottom: 15px;
}

.mayanwelcom-text{
  color: $primary;
  font-size: 2rem;
  font-weight: 800;
  padding-bottom: 15px;
}

.card-hidden{
  display: none !important;
  height: 0px !important;
  width: 0px !important;
}


