.my-orders{
.box-orders{
    margin-top: 10px;
    margin-bottom: 50px;
    padding: 35px 15px;
    -webkit-box-shadow: 0 0 4px rgb(218, 216, 216);
    box-shadow: 0 0 4px rgb(218, 216, 216);
    border-radius: 5px;
    width: 100%;
    background-color: $white;
}
}
@media screen and (max-width:600px) {
    .box-orders{
        transform: scale(0.8);
        position: relative;
        left: -13%;
        width: 135%;
    }
    
}

@media screen and (max-width:391px) {
    .box-orders{
        transform: scale(0.7);
        position: relative;
        left: -20%;
        width: 135%;
    }
    
}