@font-face {
    font-family: 'proxima-regular';
    src: url('../../../../assets/fonts/tams/proxima/FontsFree-Net-proxima_nova_reg-webfont.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'proxima-bold';
    src: url('../../../../assets/fonts/tams/proxima/FontsFree-Net-Proxima-Nova-Bold.otf') format('opentype');
    font-weight: 500;
    font-style: normal;
}