.box {
    position: relative;
    margin: 30px auto;
    padding: 20px 20px 20px;
    width: 750px !important;
    height: auto;
    -webkit-box-shadow: 0 0 4px rgb(218, 216, 216);
    box-shadow: 0 0 4px rgb(218, 216, 216);
    border-radius: 5px;
    background-color: $white;
    
    @include media-breakpoint-down(sm){
        padding: 10px 30px;
        align-items: center;
        margin-bottom: 20px;
        width : 100% !important;
    }



.form-control {
        background-color: rgb(241, 243, 247);
    }

    

.card {
    max-width: 45rem;
    padding: 0;
    border: none;
    border-radius: 0.5rem
}


label {
    font-weight: 500;
}

.form-label{
    float: left !important;
}

ul {
    list-style: none;
    margin-top: 1rem;
    padding-inline-start: 0
}
}

.customer-address-page{
    .card{
        border: 1px solid rgba(0,0,0,.125);
        border-radius: .25rem;
    }
}

.box1 {
    position: relative;
    margin: 30px auto;
    padding: 20px 20px 20px;
    width: 950px !important;
    height: auto;
    -webkit-box-shadow: 0 0 4px rgb(218, 216, 216);
    box-shadow: 0 0 4px rgb(218, 216, 216);
    border-radius: 5px;
    background-color: $white;
    
    @include media-breakpoint-down(sm){
        padding: 10px 30px;
        align-items: center;
        margin-bottom: 20px;
        width : 100% !important;
    }



.form-control {
        background-color: rgb(241, 243, 247);
    }

    

.card {
    max-width: 45rem;
    padding: 0;
    border: none;
    border-radius: 0.5rem
}


label {
    font-weight: 500;
}

.form-label{
    float: left !important;
}

ul {
    list-style: none;
    margin-top: 1rem;
    padding-inline-start: 0
}
}

.customer-address-page{
    .card{
        border: 1px solid rgba(0,0,0,.125);
        border-radius: .25rem;
    }
}
