.thumb {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
   
}
.thumb1 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin-left: 20px
   
}
@media only screen and (max-width: 768px) {
 .thumb1{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-left: 20px
  
  }
}
.thumb-content{
  text-align: left;
}
.price-area{
  margin-top: 4%;
}
.cart-buttons{
  float: right;
}
.product-description {
  width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
}
.product-list-price1{
  font-size: 20px !important;
  color: #2554C7 !important;
  font-weight: 600 !important;
}
.product-list-tile1{
  font-size: 16px !important;
  color: #000 !important;
  font-weight: 600 !important;
  overflow: hidden !important;
  display: -webkit-box !important;
  -webkit-line-clamp: 2 !important;
  -webkit-box-orient: vertical !important;
  text-overflow: ellipsis !important;
}
@media screen  and (max-width: 768px){
  .product-list-tile1{
    font-size: 12px !important;
    color: #000 !important;
    font-weight: 600 !important;
    overflow: hidden !important;
    display: -webkit-box !important;
    -webkit-line-clamp: 2 !important;
    -webkit-box-orient: vertical !important;
    text-overflow: ellipsis !important;
    height: 38px;
  }
  
}

@media (max-width: 1920px) {
  .product-list-tile2{
    font-size: 16px !important;
    color: #000 !important;
    font-weight: 600 !important;
    overflow: hidden !important;
    display: -webkit-box !important;
    -webkit-line-clamp: 2 !important;
    -webkit-box-orient: vertical !important;
    text-overflow: ellipsis !important;
  }

  .product-list-price2{
    font-size: 20px !important;
    color: #2554C7 !important;
    font-weight: 600 !important;
  }

  .rating1{
    font-size: 15px !important;
  }

}


@media (min-width: 360px) and (max-width: 600px) {
  .product-list-tile2{
    font-size: 12px !important;
    color: #000 !important;
    font-weight: 600 !important;
    overflow: hidden !important;
    display: -webkit-box !important;
    -webkit-line-clamp: 2 !important;
    -webkit-box-orient: vertical !important;
    text-overflow: ellipsis !important;
  }

  .product-list-price2{
    font-size: 12px !important;
    color: #2554C7 !important;
    font-weight: 600 !important;
  }

  .rating1{
    font-size: 12px !important;
  }
}

.product-list-description1{
  padding: 10px 15px !important;
  margin-bottom: 10px !important;
}
.product-list-image1{
  min-height: 270px !important;
  max-height: 270px !important;
  width: 100% !important;
  // width: 60% !important;
  border-radius: 10px !important;
  object-fit: contain;
  
}

.listing{
 border-radius: 6px !important;
 padding: 5px 20px !important;
        
  border: none !important;
  border-radius: 20px !important;
  font-size: 13px !important;
  font-weight: 500 !important;
}
.collection-filter {

  background-color: #fff;
  .filter-top {
    border-bottom: 1px solid #dddddd;
    .filter-icon{
      display: none;
    }
    .filter-clear {
      padding: 10px;
      display: flex;
      justify-content: space-between;
    }
  }
  .layerd-navigation {
    padding: 10px;
    // font-family: "Inter-Regular";
    border-bottom: 1px solid #dddddd;
    .collection-collapse-block {
      margin-bottom: 0px;
      .collection-collapse-block-content {
        padding-left: 30px;
        .options {
            margin: 8px 0px 8px 0px;
            input {
                margin-right: 10px;
            }
            label{
                margin-bottom: 0px;
                font-size: 15px;
                .count {
                    color: #afabab;
                    font-size: 14px;
                }
            }
        }
      }
    }
  }
}
@media screen and (max-width:768px) {
  .filter-top{
    float: right;
    position: absolute;
    left: 60%;
    margin-top: -10px;
    top: 0%;
    border: none !important;
    .filter-icon{
      display: inline !important;
    }
  }
  .thumb {
    grid-template-columns: repeat(1, 1fr);
    width:111%;
    margin-left:-25px;
  }
  .product-description {
    width: 100% !important;
    display: grid;
    white-space: break-spaces;
  }
  
}