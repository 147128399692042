.header-cart {
    .min-cart-product-wrapper {
        .min-cart-group {
            display: flex;
            border-bottom:1px solid #e6e5e5;
            .img-wrapper {
                max-width: 80px;
                display: flex;
                .btn-close {
                    span{
                        font-size:14px;
                    }
                }
            }
            .product-detail {
                margin-right: 5px;
                .product-name {
                    // text-transform: uppercase;
                    font-size:14px;
                }
            }
        }
        .cart-summary {
            .summary-details {
                    ul {
                        columns: 2;
                        -webkit-columns: 2;
                        -moz-columns: 2;
                        li {
                            .btn {
                                font-size: 14px;
                                // text-transform: uppercase;
                            }
                        }
                    }
            }
        }
        
    }
    
}

.account-popup {
        .popup-content { 
            overflow-y: auto;
            height: fit-content;
            max-height: 370px;
            padding: 15px;
            min-width: 300px;
            .new-register {
                margin: 10px 0px 0px 0px;
                font-size: 14px;
                a {
                    color: #fff;
                }
            }
        }
    }
    .popup-content {
        position: absolute;
        top: calc(100%);
        width: 100%;
        max-width: 280px;
        width:300px;
        right: 0;
        padding: 20px 0;
        background: #fff;
        box-shadow: 0 3px 5px 0 ;
        //box-shadow: 2px 10px 7px 0px rgba(84,84,84,0.27);
        transition-delay: .3s;
        opacity: 0;
        transform: translate3d(0, 50px, 0);
        -webkit-transform: translate3d(0, 50px, 0);
        -moz-transform: translate3d(0, 50px, 0);
        -o-transform: translate3d(0, 50px, 0);
        -ms-transform: translate3d(0, 50px, 0);
        transition: transform .4s ease 0s,opacity .4s ease 0s,visibility .4s ease 0s;
        -moz-transition: -moz-transform .4s ease 0s,opacity .4s ease 0s,visibility .4s ease 0s;
        -webkit-transition: -webkit-transform .4s ease 0s,opacity .4s ease 0s,visibility .4s ease 0s;
        -ms-transition: -ms-transform .4s ease 0s,opacity .4s ease 0s,visibility .4s ease 0s;
        visibility: hidden;
        z-index: 1001;
        padding: 20px 0;
        background: #fff;
    //	border: 1px solid #d1d1d1;
        &.profile-link{
            padding:0px;
        }
    }

    .account-popup:hover {
        .popup-content{
            display: block;
            margin: 0;
            opacity: 1;
            transform: translate3d(0, 0, 0);
            -webkit-transform: translate3d(0, 0, 0);
            -moz-transform: translate3d(0, 0, 0);
            -o-transform: translate3d(0, 0, 0);
            -ms-transform: translate3d(0, 0, 0);
            visibility: visible;
        }
    
}

@media screen and (max-width:600px) {

     .account-popup:hover {
        .popup-content{
            display: none !important;
        }
    }
}

.account-popup1 {
    .popup-content1 { 
        overflow-y: auto;
        height: fit-content;
        max-height: 370px;
        padding: 15px;
        width: 100%;
        // min-width: 1300px;
        .new-register {
            margin: 10px 0px 0px 0px;
            font-size: 14px;
            a {
                color: #fff;
            }
        }
    }

    .popup-content2 { 
        overflow-y: auto;
        height: 175px;
        max-height: 370px;
        padding: 15px;
        width: 100%;
        // min-width: 1300px;
        .new-register {
            margin: 10px 0px 0px 0px;
            font-size: 14px;
            a {
                color: #fff;
            }
        }
    }
}
.popup-content1 {
    position: absolute;
    top: calc(100%);
    width: 100%;
    min-width: 100%;
    // width:1300px;
    right: 0;
    padding: 20px 0;
    background: #fff;
    box-shadow: 0 3px 5px 0 ;
    box-shadow: 2px 10px 7px 0px rgba(84,84,84,0.27);
    transition-delay: .3s;
    opacity: 0;
    transform: translate3d(0, 50px, 0);
    -webkit-transform: translate3d(0, 50px, 0);
    -moz-transform: translate3d(0, 50px, 0);
    -o-transform: translate3d(0, 50px, 0);
    -ms-transform: translate3d(0, 50px, 0);
    transition: transform .4s ease 0s,opacity .4s ease 0s,visibility .4s ease 0s;
    -moz-transition: -moz-transform .4s ease 0s,opacity .4s ease 0s,visibility .4s ease 0s;
    -webkit-transition: -webkit-transform .4s ease 0s,opacity .4s ease 0s,visibility .4s ease 0s;
    -ms-transition: -ms-transform .4s ease 0s,opacity .4s ease 0s,visibility .4s ease 0s;
    visibility: hidden;
    z-index: 1001;
    padding: 20px 0;
    background: #fff;
//	border: 1px solid #d1d1d1;
    &.profile-link{
        padding:0px;
    }
}

.popup-content2 {
    position: absolute;
    top: calc(100%);
    width: 100%;
    min-width: 100%;
    // width:1300px;
    right: 0;
    padding: 20px 0;
    background: #fff;
    box-shadow: 0 3px 5px 0 ;
    box-shadow: 2px 10px 7px 0px rgba(84,84,84,0.27);
    transition-delay: .3s;
    opacity: 0;
    transform: translate3d(0, 50px, 0);
    -webkit-transform: translate3d(0, 50px, 0);
    -moz-transform: translate3d(0, 50px, 0);
    -o-transform: translate3d(0, 50px, 0);
    -ms-transform: translate3d(0, 50px, 0);
    transition: transform .4s ease 0s,opacity .4s ease 0s,visibility .4s ease 0s;
    -moz-transition: -moz-transform .4s ease 0s,opacity .4s ease 0s,visibility .4s ease 0s;
    -webkit-transition: -webkit-transform .4s ease 0s,opacity .4s ease 0s,visibility .4s ease 0s;
    -ms-transition: -ms-transform .4s ease 0s,opacity .4s ease 0s,visibility .4s ease 0s;
    visibility: hidden;
    z-index: 1001;
    padding: 20px 0;
    background: #fff;
//	border: 1px solid #d1d1d1;
    &.profile-link{
        padding:0px;
    }
}

.account-popup1:hover {
    .popup-content1{
        display: block;
        margin: 0;
        opacity: 1;
        transform: translate3d(0, 0, 0);
        -webkit-transform: translate3d(0, 0, 0);
        -moz-transform: translate3d(0, 0, 0);
        -o-transform: translate3d(0, 0, 0);
        -ms-transform: translate3d(0, 0, 0);
        visibility: visible;
    }

    .popup-content2{
        display: block;
        margin: 0;
        opacity: 1;
        transform: translate3d(0, 0, 0);
        -webkit-transform: translate3d(0, 0, 0);
        -moz-transform: translate3d(0, 0, 0);
        -o-transform: translate3d(0, 0, 0);
        -ms-transform: translate3d(0, 0, 0);
        visibility: visible;
    }

}

.close
{
    float: right;
    font-size: medium;
    color: $pallet2;
}
.close:hover
{
    cursor: pointer;
}

.txt:hover {
    text-decoration: underline;
}

@media screen and (max-width:600px) {
    .header-cart{
        transform: scale(0.8);
        
    }
    
}