.global-search::-ms-clear {
    display: none;
}
.flex-container {
    display: flex;
    flex-direction: row;
}
.inq-sec-h-fld-st {
    width: 100%;
    margin-left: 5%;
}
.inq-sec-h-fld-st span {
    position: absolute;
    right: 6%;
    top: 6px;
}
.inq-sec-h-fld-st span .fa {
    font-size: 18px;
    color: $light-ash;
    cursor: pointer;
}
.inq-sec-h-st .inq-sec-h-fld-st {
    position: relative;
}
.inq-sec-h-fld-st .form-control {
    height: calc(2em + .75rem + 2px);
    border-radius: 1.5rem;
    position: relative;
    margin-top: 0.4rem;
    width: 100%;
}
.inq-sec-h-st .inq-sec-h-fld-st span {
    position: absolute;
    right: 17px;
    top: 10px;
}
.aiema-logo{
    margin-left: 40px;
}
@media screen and (max-width: 576px) {
    .flex-container{
        margin-left: 0px;
    }
    .aiema-logo{
        transform: scale(0.75);
        top:0px !important;
        left:40%;
        position:fixed  ;
        margin-left: 0px;
        z-index:3;
    }
    .logo-image {
        height: 40px;
        margin-top: 5px;
        margin-left: 30px !important;
    }
    #secondpart {
        margin-left: -11% !important;
        padding-top: 20px;
    }
    .inner-cart p {
        left: 28px !important;
    }
    #navigtion {
        margin-top: 35px !important;
    }
    #reward-prt {
        padding : 8px 0px 0px 10px !important;
    }
}

@media screen and (max-width:767px) and (min-width:577px){
    .inner-review p{
        left: 16% !important;
    }
    #secondpart {
        margin-left: -6% !important;
        padding-top: 25px;
    }
    #navigtion {
        margin-top: 35px !important;
    }
}
@media screen and (max-width:1000px) and (min-width:768px){
    .inner-review p{
        left: 21% !important;
    }
    #secondpart {
        margin-left: -6% !important;
        padding-top: 20px;
    }
}
.flex-container-large {
    display: flex;
    justify-content: space-around;
}
.ineer-box {
    // height: 140px;
    // width: 140px;
    // background-color: red;
}
.dropdown-toggle .text{
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 150px;
    display: inline-block;
    vertical-align: middle;
  }

.inner-review img {
    position: relative;
    max-width: 74%;
    padding-top: 7px;
}
.inner-review p {
    left: 18%;
    position: absolute;
    top: 17%;
}
.inner-review{position:relative;}

.checked {
    color: $checked-star;
}
.unchecked {
    color: $unchecked-star;
    font-size: 12px;
}
.line {
    line-height: 0.2;
}

.inner-chef img {
    max-height: 82%;
}

.log-prt p:first-child {
    font-weight: 500;
}
.log-prt {
    line-height: 1.3;
    // text-transform: uppercase;
    font-size: 13px;
}
.log-prt p{
    font-size: 14px;     color: #828281;
}

.inner-cart {
    position: relative;
}
.inner-cart p{
    position: absolute;
    left: 24px;
}
.inner-cart img {
    position: absolute;
    max-height: 85%;
}
.line[_ngcontent-mnf-c20] h4{margin-bottom:0;}

.show-subtotal
{
    margin: auto;
    padding-left: 45px;
    font-size: small;
}



.ng-select .ng-select-container {
    border-radius: 30px !important;
}
.cart-link:hover
{
    cursor: pointer;
}

.autocomplete-items {
  position: absolute;
  border: 1px solid #d4d4d4;
  border-bottom: 2px solid #ddd;
  width: 65%;
  border-top: none;
  z-index: 99;
  font-size: 13px !important;
  /*position the autocomplete items to be the same width as the container:*/
  top: 80%;
  left: 150px;
  right: 0;
  max-height: 400px;
  overflow: auto;
  border-radius: 15px;
}
.autocomplete-items li {
  padding: 10px;
  cursor: pointer;
  background-color: #fff;
  font-size: 13px !important;
  // border-bottom: 1px solid #d4d4d4;
}
.autocomplete-items li:hover {
  /*when hovering an item:*/
  background-color: #e9e9e9;
}
.autocomplete-active {
  /*when navigating through the items using the arrow keys:*/
  background-color: $primary !important;
  color: #ffffff;
}
.titleproper{
  text-transform: lowercase;
}

.titleproper:first-line {
  text-transform: capitalize;
}







