.checkout-forms{
    margin-right: 0px;
    font-size: 12px;
    .invalid-feedback{
        font-size: 12px !important;
      }
    label{
        margin-bottom:15px;
        position:relative;
        border-bottom:1px solid #ddd;
        width: 100%;
        margin-bottom:0px !important;
    }
    .invalid-feedback{
        font-size: 12px !important;
    }
    input{
        width:100%;
        padding:10px 0px;
        margin-top:20px;
        font-size: 14px;
        border:none !important;
        outline:none !important;
    }
    textarea{
        width:100%;
        padding:10px 0px;
        margin-top:20px;
        font-size: 14px;
        border:none !important;
        outline:none !important;
    }
    .form-group{
        margin-bottom: 0px !important;
    }
    .form-control{
        border: none !important;
        outline: none !important;
        box-shadow: none !important;
    }
    .form-control:focus{
        border: none !important;
        outline: none !important;
        box-shadow: none !important;
    }
    input:focus{
        border: none;
        outline: none;
    }
    input::placeholder{
        opacity:0;
    }
    textarea::placeholder{
        opacity:0;
    }
    span{
        position:absolute;
        top:0;
        left:0;
        transform:translateY(30px);
        font-size:0.825em;
        transition-duration:300ms;
    }

    .checkout-label{
        color: $light-ash;
    }
    
    label:focus-within > span,
    input:not(:placeholder-shown) + span{
        color:$primary;
        transform:translateY(0px);
    }
    label:focus-within > span,
    textarea:not(:placeholder-shown) + span{
        color:$primary;
        transform:translateY(0px);
    }
}

.btn-primary1{
    padding:8px 0px;
    border-radius: 0px;
    margin-top: 20px;
    font-size: 14px;
    border-radius: 3px !important;
    font-weight: 500;
    // background-color: #121213 !important;
}

.btn-timeslot{
    border-radius: 5px;
    border: 0px;
    background-color: grey;
    color: white;
    margin-right: 5px;
    margin-left: 5px;
    padding: 4px 15px;
    
}

.btn-timeslot1{
    border-radius: 5px;
    border: 0px;
    background-color:$primary;
    color: white;
    margin-right: 5px;
    margin-left: 5px;
    padding: 4px 15px;
    
}


.btn-timeslot:focus {
    background-color:$primary;
  }

  .btn-timeslot.selected{
    color:$primary;
  }

.btn-secondary1{
    padding:8px 20px;
    border-radius: 0px;
    color: $secondary;
    font-size: 14px;
    border-radius: 3px !important;
    background-color: $white !important;
    border: 1px solid $primary !important;
    font-weight: 500;
    color: $primary !important;
}

.btn-secondary1:hover{
    background-color: $primary !important;
    color: $white !important;
}

.customer-container{
    padding: 15px 30px 25px;
    
}

.custom-link{
    margin-bottom: 10px; 
    color: $menu-color !important; 
    font-weight: bold; 
    cursor: pointer; 
}

.forgot-link{
    color: $menu-color !important; 
    font-weight: bold; 
    cursor: pointer; 
    margin-top: 5px;
}

.custom-radios{
    margin-left: 20px;
}

.custom-radio-text{
    color: $menu-color;
}

.login-link-color{
    color: $primary !important;
}

@media  screen and (max-width:600px) {
    .customer-container{
        padding: 0px 0px 20px !important;
        
    }
    
}